<!-- eslint-disable -->
<template>
  <div>
    <v-data-table :sort-by="ativarFiltragem.item" :sort-desc="ativarFiltragem.desc" :item-class="isDevolucao"
      single-expand dark :custom-filter="dateFilterFunc" :headers="h" :items="dadosFiltrados" :expanded.sync="expanded"
      item-key="objectId" show-expand class="quinta elevation-1 c-table-historico__sellout" :search="search">

      <!-- BARRA DE PROGRESSO E INPUTS DE DATAS -->
      <template v-slot:top>
        <v-progress-linear absolute top :height="8" :active="loopLoading" :indeterminate="loopLoading" color="#727272"
          class="pt-1"></v-progress-linear>

        <!-- INPUTS DE DATAS -->
        <v-row align="center" align-content="center" class="mx-4">
          <!-- INPUT SELEÇÃO DE DATA INICIO -->
          <v-col cols="12" md="7" class="text-center">
            <input type="datetime-local" v-model="dateFilter.start" label="Data inicial" class="c-input-data">
            <v-divider class="mx-4" vertical />
            <!-- INPUT SELEÇÃO DE DATA FIM -->
            <input type="datetime-local" v-model="dateFilter.end" label="Data final" class="c-input-data">
            <!-- BOTÃO DE DOWNLOAD DOS DIAS SELECIONADO -->
            <!-- <v-btn color="sexta" height="44" class="ml-4 my-2" @click="downloadHtmlEstilizado">
              BAIXAR HISTÓRICO
              <v-icon right>file_download</v-icon></v-btn> -->
          </v-col>
          <!-- BOTÃO DE DOWNLOAD DOS DIAS SELECIONADO -->
          <v-col cols="12" md="5" class="my-0 py-0 text-center d-flex align-center c-col-botoes">
            <v-chip-group v-model="tipoDeDocumentoParaDownload" column multiple>
              <v-chip filter outlined>
                CSV
              </v-chip>
              <v-chip filter outlined>
                HTML
              </v-chip>
              <v-chip filter outlined>
                PDF
              </v-chip>
            </v-chip-group>
            <v-btn :loading="loadingDownload" :disabled="tipoDeDocumentoParaDownload.length === 0" fab elevation="1"
              width="38" height="38" color="sexta" @click="baixarArquivos">
              <v-icon>file_download</v-icon>
            </v-btn>
          </v-col>
          <!-- INPUT BUSCADOR -->
          <v-col cols="12" class="mb-4">
            <v-text-field outlined class="mx-3" v-model="search" append-icon="mdi-magnify" label="Buscar Histórico"
              single-line hide-details></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`expanded-item`]="{ headers, item }">

        <td :colspan="headers.length">
          <div v-for="(produto, idx) in item.produtos" :key="idx">
            <v-chip label color="quarta" class="mt-2"> Produto: {{ produto.nomeProduto }} | {{ produto.unidade }} {{
      produto.unidade > 1 ? "Unidades" : "Unidade" }} </v-chip> <br />
            <span class="pl-1"> Unidade Medida: {{ produto.unidadeMedida }} | Fator: {{ produto.fator }}</span>
            <v-divider class="ma-2 linha-endereco" />
          </div>
        </td>

      </template>

      <template v-slot:item.dataEnvio="props">
        {{ identificarTipoData2(props.item.dataEnvio) }}
        <span v-if="'logs' in props.item" class="c-logs">
          <strong>Usuário: </strong>{{ props.item.logs.email }}<br />
          <strong>Enviado Para: </strong>
          <span>
            <v-icon small @click="verLinkApi(props.item.logs.linkApi)">visibility</v-icon>
          </span>
        </span>
      </template>

      <template v-slot:item.periodo="props">
        {{ formatDataBr(props.item.periodo) }}
      </template>

      <template v-slot:item.dataNf="props">
        {{ formatDataBr(props.item.dataNf) }}
      </template>

    </v-data-table>
    <v-dialog v-model="dialogVerLink" hide-overlay width="300">
      <v-card dark color="sexta">
        <v-card-title class="text-body-2">
          Link API: {{ linkApiEnviado }}
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';


import { urlApi, showError, cofingK } from "@/global";
import axios from "axios";
export default {
  name: "historicoSellOut",
  props: { openHistoricoSell: Boolean },
  components: {},
  data: () => ({
    loadingDownload: false,
    tipoDeDocumentoParaDownload: [],
    dialogVerLink: false,
    linkApiEnviado: '',
    dateFilter: {
      start: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 16),
      end: new Date().toISOString().slice(0, 16)
    },
    loopLoading: false,
    search: "",
    expanded: [],
    dataHistoricoSell: [],
    h: [
      {
        text: "objectId",
        align: "start",
        sortable: true,
        value: "objectId",
        class: "u-hide",
        cellClass: "u-hide",
      },
      { align: "center", text: "Empresa", value: "empresa" },
      { align: "center", text: "Cliente", value: "cliente", cellClass: "u-font-12" },
      { align: "center", text: "Nota Fiscal", value: "notaFiscal" },
      { align: "center", text: "CNPJ", value: "cnpj" },
      { align: "center", text: "Cliente Entrega", value: "clienteEntrega", cellClass: "u-font-12 pt-1" },
      { align: "center", text: "CNPJ Entrega", value: "cnpjEntrega" },
      { align: "center", text: "Data do Envio", value: "dataEnvio" },
      { align: "center", text: "Periodo", value: "periodo" },
      { align: "center", text: "Data NF", value: "dataNf" },
      { align: "center", text: "Tipo de Documento", value: "tipoDeDocumento" },
      { align: "center", text: "Produtos", value: "data-table-expand" },
      { align: "center", text: "Logs", value: "logs", class: "u-hide", cellClass: "u-hide", }
    ],
  }),
  computed: {
    ativarFiltragem() {
      if (this.openHistoricoSell) {
        let sort = { item: 'dataEnvio', desc: true }
        return sort
      } else {
        let sort = { item: [], desc: false }
        return sort
      }
    },
    identificarTipoData2() {
      return data => {
        let dataObjeto;
        let dataFormatada = '';
        let horaFormatada = '';

        if (data.includes('T')) {
          dataObjeto = new Date(data);

          if (!isNaN(dataObjeto)) {
            const dia = dataObjeto.getDate().toString().padStart(2, '0');
            const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObjeto.getFullYear();

            dataFormatada = `${dia}/${mes}/${ano}`;

            const hora = dataObjeto.getHours().toString().padStart(2, '0');
            const minutos = dataObjeto.getMinutes().toString().padStart(2, '0');
            const segundos = dataObjeto.getSeconds().toString().padStart(2, '0');

            horaFormatada = `${hora}h:${minutos}min:${segundos}s`;
          }
        } else {
          dataObjeto = new Date(data);

          if (!isNaN(dataObjeto)) {
            const dia = dataObjeto.getDate().toString().padStart(2, '0');
            const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
            const ano = dataObjeto.getFullYear();

            dataFormatada = `${dia}/${mes}/${ano}`;
          }
        }
        return `${dataFormatada} ${horaFormatada}`.trim();
      }
    },
    identificarTipoData() {
      return data => {
        if (data.includes('T')) {
          // Data com hora
          const dataHora = new Date(data);
          const dia = dataHora.getDate().toString().padStart(2, '0');
          const mes = (dataHora.getMonth() + 1).toString().padStart(2, '0');
          const ano = dataHora.getFullYear();
          const hora = dataHora.getHours().toString().padStart(2, '0');
          const minutos = dataHora.getMinutes().toString().padStart(2, '0');
          const dataFormatada = `${dia}/${mes}/${ano} Hora: ${hora}:${minutos}min.`;
          return dataFormatada;
        } else {
          // Data apenas
          const dataArray = data.split('-');
          const dia = dataArray[2];
          const mes = dataArray[1];
          const ano = dataArray[0];
          const dataFormatada = `${dia}/${mes}/${ano}`;
          return dataFormatada;
        }
      }
    },
    formatDataBr() {
      return (data => this.dataFormatadaBr(data))
    },
    dadosFiltrados() {
      let dfiltrado = this.dataHistoricoSell.filter(item => {
        if (!this.dateFilter.start || !this.dateFilter.end) {
          return true; // Se não houver filtro de data definido, exibir todos os itens
        }

        const startDate = new Date(this.dateFilter.start);
        const endDate = new Date(this.dateFilter.end);
        const itemDate = new Date(item.dataEnvio); // Substitua 'item.date' pelo nome da propriedade de data no seu objeto de dados


        const isDateMatch = itemDate >= startDate && itemDate <= endDate;

        const isTextMatch = this.search === '' || item.clienteEntrega.toLowerCase().includes(this.search.toLowerCase()) || item.cliente.toLowerCase().includes(this.search.toLowerCase()) || item.empresa.toLowerCase().includes(this.search.toLowerCase());
        return isDateMatch && isTextMatch;
      })
      return dfiltrado
    }
  },
  watch: {
    openHistoricoSell: {
      handler(novo) {
        if (novo) {
          this.listarHistoricoSell();
          this.atualizarDataHoraModelo();
        } else {
          this.dataHistoricoSell = [];
        }
      },
      immediate: true,
    },

  },

  methods: {
    baixarArquivos() {
      const tiposSelecionados = this.tipoDeDocumentoParaDownload;

      // Verificar se o usuário selecionou algum tipo de documento
      if (!tiposSelecionados.includes(0) && !tiposSelecionados.includes(1) && !tiposSelecionados.includes(2)) {
        console.error('Por favor, selecione pelo menos um tipo de documento para download.');
        return;
      }

      // Definir a ordem dos tipos de documento para download
      const ordemDownload = ['CSV', 'HTML', 'PDF'];

      // Atualizar o loadingDownload para true
      this.loadingDownload = true;

      // Executar o download para cada tipo na ordem definida
      ordemDownload.forEach((tipo, index) => {
        if (tiposSelecionados.includes(index)) {
          switch (tipo) {
            case 'CSV':
              this.downloadCSV();
              break;
            case 'HTML':
              this.downloadHtmlEstilizado();
              break;
            case 'PDF':
              this.downloadPdfEstilizado();
              break;
            default:
              console.error(`Tipo de documento inválido: ${tipo}`);
          }
        }
        // Definir o loadingDownload de volta para false após o download estar completo
        this.loadingDownload = false;
      });
    },
    downloadPdfEstilizado() {
      if (!this.dateFilter || !this.dateFilter.start || !this.dateFilter.end) {
        console.error("dateFilter não está definido.");
        return;
      }

      const reversedData = this.dadosFiltrados.slice().reverse();

      // Função para extrair os dados dos produtos
      const extractProductData2 = (products) => {
        if (!products || products.length === 0) return ''; // Retornar vazio se não houver produtos

        const productData = products.map(product => {
          return `${product.nomeProduto}: ${product.unidade} ${product.unidade > 1 ? "Unidades" : "Unidade"}; Unidade Medida: ${product.unidadeMedida}; Fator: ${product.fator}<hr style="width: 300px; margin: 10px 0;">`;
        });

        return productData.join(''); // Não é necessário adicionar um separador no final
      };

      const htmlContent = `
    <html>
    <head>
      <title>Histórico Sellout</title>
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
          font-size: 10px;
        }
        th, td {
          border: 1px solid black;
          padding: 5px;
          text-align: left;
          vertical-align: top; /* Alinhar o texto ao topo */
        }
        th {
          background-color: #f2f2f2;
          font-weight: bold; /* Aplicar negrito nos títulos */
        }
        .info-col, .entrega-col, .datas-col, .tipo-doc-col, .produto-col {
          width: 20%; /* Definindo largura das colunas */
        }
      </style>
    </head>
    <body>
      <h2>Histórico Sellout</h2>
      <table>
        <thead>
          <tr>
            <th class="info-col">Informações</th>
            <th class="entrega-col">Dados da Entrega</th>
            <th class="datas-col">Datas</th>
            <th class="tipo-doc-col">Tipo de Documento</th>
            <th class="produto-col">Produtos</th> <!-- Movida para o final -->
          </tr>
        </thead>
        <tbody>
          ${reversedData.map(item => `
            <tr>
              <td>
                <b>Empresa:</b> ${item.empresa}<br>
                <b>Cliente:</b> ${item.cliente}<br>
                <b>Nota Fiscal:</b> ${item.notaFiscal}<br>
                <b>CNPJ:</b> ${item.cnpj}
              </td>
              <td>
                <b>Cliente Entrega:</b> ${item.clienteEntrega}<br>
                <b>CNPJ Entrega:</b> ${item.cnpjEntrega}
              </td>
              <td>
                <b>Data do Envio:</b> ${this.formatDataEnvio(item.dataEnvio)}<br>
                <b>Período:</b> ${this.formatDataBr(item.periodo)}<br>
                <b>Data NF:</b> ${this.formatDataBr(item.dataNf)}
              </td>
              <td>${item.tipoDeDocumento}</td>
              <td>${extractProductData2(item.produtos)}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
    </body>
    </html>
  `;

      const container = document.createElement('div');
      container.innerHTML = htmlContent;

      const pdf = new jsPDF({ orientation: 'portrait', unit: 'pt', format: [800, 1100] });
      pdf.html(container, {
        callback: function (pdf) {
          pdf.save(`historico_sellout.pdf`);
        }
      });
    },
    downloadHtmlEstilizado() {
      // 1. Filtrar os dados com base no intervalo de data selecionado
      const filteredData = this.dadosFiltrados;

      // 2. Inverter a ordem dos dados para da mais nova para a mais antiga
      const reversedData = filteredData.slice().reverse();

      // 3. Construir o conteúdo HTML
      let htmlContent = `
    <html>
    <head>
      <title>Histórico Sellout</title>
      <style>
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
        }
      </style>
    </head>
    <body>
      <h2>Histórico Sellout</h2>
      <table>
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Cliente</th>
            <th>Nota Fiscal</th>
            <th>CNPJ</th>
            <th>Cliente Entrega</th>
            <th>CNPJ Entrega</th>
            <th>Data do Envio</th>
            <th>Período</th>
            <th>Data NF</th>
            <th>Tipo de Documento</th>
            <th>Produtos</th>
          </tr>
        </thead>
        <tbody>
  `;

      reversedData.forEach(item => {
        htmlContent += `
      <tr>
        <td>${item.empresa}</td>
        <td>${item.cliente}</td>
        <td>${item.notaFiscal}</td>
        <td>${item.cnpj}</td>
        <td>${item.clienteEntrega}</td>
        <td>${item.cnpjEntrega}</td>
        <td>${this.formatDataEnvio(item.dataEnvio)}</td>
        <td>${this.formatDataBr(item.periodo)}</td>
        <td>${this.formatDataBr(item.dataNf)}</td>
        <td>${item.tipoDeDocumento}</td>
        <td>${this.extractProductData(item.produtos)}</td>
      </tr>
    `;
      });

      // Finalizar o conteúdo HTML
      htmlContent += `
        </tbody>
      </table>
    </body>
    </html>
  `;

      // 4. Criar um blob com o conteúdo HTML
      const blob = new Blob([htmlContent], { type: 'text/html' });

      // 5. Criar um link de download e simular o clique
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `historico_sellout_${this.dateFilter.start}_${this.dateFilter.end}.html`;
      link.click();
    },
    downloadCSV() {
      // 1. Filtrar os dados com base no intervalo de data selecionado
      const filteredData = this.dadosFiltrados;

      // 2. Inverter a ordem dos dados para da mais nova para a mais antiga
      const reversedData = filteredData.slice().reverse();

      // 3. Converter os dados para o formato desejado (por exemplo, CSV)
      const csvData = this.convertToCSV(reversedData);

      // 4. Criar um objeto Blob com os dados CSV
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

      // 5. Criar um link de download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `historico_sellout_${this.dateFilter.start}_${this.dateFilter.end}.csv`;

      // 6. Simular o clique no link de download
      link.click();
    },

    // Função auxiliar para converter os dados em CSV
    convertToCSV(data) {
      // Personalize os cabeçalhos e a extração de dados com base na estrutura dos seus dados
      const headers = [
        'Empresa',
        'Cliente',
        'Nota Fiscal',
        'CNPJ',
        'Cliente Entrega',
        'CNPJ Entrega',
        'Data do Envio',
        'Periodo',
        'Data NF',
        'Tipo de Documento',
        'Produtos'
      ];

      const rows = data.map(item => [
        item.empresa,
        item.cliente,
        item.notaFiscal,
        item.cnpj,
        item.clienteEntrega,
        item.cnpjEntrega,
        this.formatDataEnvio(item.dataEnvio), // Formatar a data de envio
        this.formatDataBr(item.periodo), // Formatar o período
        this.formatDataBr(item.dataNf), // Formatar a data da NF
        item.tipoDeDocumento,
        this.extractProductData(item.produtos) // Extrair os dados dos produtos
      ]);
      return [headers, ...rows].map(row => row.join(',')).join('\n');
    },

    // Função auxiliar para extrair os dados dos produtos e formatá-los para CSV
    extractProductData(products) {
      if (!products || products.length === 0) return ''; // Retornar vazio se não houver produtos

      const productData = products.map(product => {
        return `${product.nomeProduto}: ${product.unidade} ${product.unidade > 1 ? "Unidades" : "Unidade"}; Unidade Medida: ${product.unidadeMedida}; Fator: ${product.fator}`;
      });

      return productData.join('; '); // Separar os dados dos produtos por ponto e vírgula
    },

    formatDataEnvio(data) {
      const dataObjeto = new Date(data);
      const dia = dataObjeto.getDate().toString().padStart(2, '0');
      const mes = (dataObjeto.getMonth() + 1).toString().padStart(2, '0');
      const ano = dataObjeto.getFullYear();
      const hora = dataObjeto.getHours().toString().padStart(2, '0');
      const minutos = dataObjeto.getMinutes().toString().padStart(2, '0');
      const segundos = dataObjeto.getSeconds().toString().padStart(2, '0');
      return `${dia}/${mes}/${ano} ${hora}h:${minutos}min:${segundos}s`;
    },

    verLinkApi(url) {
      this.linkApiEnviado = this.encurtarUrl(url)
      this.dialogVerLink = true
    },
    encurtarUrl(url) {
      const indiceSegundoHttp = url.indexOf("https://", url.indexOf("https://") + 1);
      const resultado = indiceSegundoHttp !== -1 ? url.slice(indiceSegundoHttp) : url;
      return resultado;
    },
    atualizarDataHoraModelo() {
      const agora = new Date();
      agora.setMinutes(agora.getMinutes() - agora.getTimezoneOffset() + 60);
      this.dateFilter.end = agora.toISOString().slice(0, 16);
      this.dateFilter.start = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 16);
    },
    isDevolucao(item) {
      return { 'c-venda-devolvida': item.tipoDeDocumento === "CL" ? true : false }
    },
    dataFormatadaBr(data) {
      const dataString = data;

      // Dividir a string da data em partes separadas
      const [ano, mes, dia] = dataString.split('-');

      // Formatar a data no formato desejado
      return `${dia}/${mes}/${ano}`;
    },
    dateFilterFunc(value, search, item) {
      if (!this.dateFilter.start || !this.dateFilter.end) {
        return true; // Se não houver filtro de data definido, exibir todos os itens
      }

      const startDate = new Date(this.dateFilter.start);
      const endDate = new Date(this.dateFilter.end);
      const itemDate = new Date(item.dataEnvio); // Substitua 'item.date' pelo nome da propriedade de data no seu objeto de dados

      const isDateMatch = itemDate >= startDate && itemDate <= endDate;

      const isTextMatch = search === '' || item.clienteEntrega.toLowerCase().includes(search.toLowerCase()) || item.cliente.toLowerCase().includes(search.toLowerCase()) || item.empresa.toLowerCase().includes(search.toLowerCase());

      return isDateMatch && isTextMatch;

    },
    listarHistoricoSell() {
      this.loopLoading = true
      axios
        .post(
          `${urlApi}/listar-historico-sellout`,
          {},
          {
            headers: cofingK,
          }
        )
        .then((res) => {
          this.dataHistoricoSell = res.data.result;
          this.$toasted.global.defaultSuccess();
          this.loopLoading = false
        })
        .catch(err => {
          showError(err)
          this.loopLoading = false
        });
    },
  },
  created() {
  },
  beforeMount() { },
};
</script>

<style>
.c-col-botoes {
  border: solid 1px grey;
  border-radius: 5px;
}

.c-table-historico__sellout thead.v-data-table-header {
  background: #313131;
}

.c-table-historico__sellout th i {
  display: none !important;
}

td.text-start.u-font-12 {
  font-size: 12px !Important;
  padding: 7px !important;
  background: #3331ab;
}

.c-venda-devolvida:hover {
  background: #F44336 !important;
}

.c-venda-devolvida {
  background: red;
}

.c-input-data {
  border: solid 1px white;
  padding: 10px;
  border-radius: 5px;
  background: whitesmoke;
}

.c-table-historico__sellout table td {
  overflow-x: scroll;
  border: solid 1px #727272;
  font-size: 13px !important;
}

.c-table-historico__sellout thead th {
  border: solid 1px #727272;
  padding: 0px 5px !important;
  width: 260px !important;
}
</style>
